import React from 'react'

import Layout from '../components/Layout/layout-single'
import SEO from '../components/SEO/seo'
import Linklist from '../components/Linklist/list'
import Header from '../components/Header/header'

class SitemapPage extends React.Component {
  render () {
    const listRows = [
      { title: 'Welcome', url: '/', img: 'home', layer: '1' },
      { title: 'Projects', url: '/project', img: 'all', layer: '2' },
      { title: 'Blog', url: '/blog', img: 'blog', layer: '2' },
      { title: 'Museum', url: '/all', img: 'all', layer: '2' },
      { title: '404', url: '/not-a-page', img: '404', layer: '1' }
    ]

    return (
      <Layout>
        <>
          <SEO title="Sitemap" imagefile="all" location={this.props.location} />
          <Header
            location={this.props.location}
            alt="Sitemap"
            title="Sitemap"
          />
        </>
        <>
          <h1>Sitemap</h1>
          <p>Maybe one of these pages is the page you are looking for...</p>
          <Linklist items={listRows} small={true}/>
        </>
      </Layout>
    )
  }
}

export default SitemapPage
