import React from 'react'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'

import Footer from '../Footer/footer'
import './layout-single.scss'

class Layout extends React.Component {
  render () {
    const typeClass = this.props.type ? `layout-single--${this.props.type}` : ''
    const activeTab = this.props.activeTab || ''

    let layoutHeader = false
    if (this.props.children[0]) {
      layoutHeader = (
        <div className="layout-single__header">
          {this.props.children[0]}
        </div>
      )
    }

    let layoutBottom = false
    if (this.props.children[2]) {
      layoutBottom = (
        <div className="layout-single__bottom">
          {this.props.children[2]}
        </div>
      )
    }

    return (
      <StickyContainer>
        <div className={`layout-single ${typeClass}`}>
          {layoutHeader}
          <main>
            <div>
              {this.props.children[1]}
            </div>
          </main>
          {layoutBottom}
          <Footer />
        </div>
      </StickyContainer>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
