import React from 'react'
import { Link } from 'gatsby'

import './list.scss'

class List extends React.Component {
  render () {
    const listElement = this.props.items.map((row) => {
      return (
        <li className="link-options__option" key={`list-item-${row.url}`}>
          <Link to={row.url} ><h2>{row.title}</h2></Link>
          {row.img ? (<a href={row.url} ><img src={`/share-img/${row.img}.png`} alt={row.img} /></a>) : ''}
          {row.iframe ? (
            <div className="iframe__wrapper">
              <div className="iframe__wrapper-inner"><iframe title="Inline Frame Example" src={row.url}></iframe></div>
            </div>
          ) : ''}
        </li>
      )
    })

    return (
      <ul className={`link-options ${this.props.small ? 'link-options--small' : ''}`}>
        {listElement}
      </ul>
    )
  }
}

export default List
